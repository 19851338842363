import axios, { type AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { useNavigate, useParams } from 'react-router-dom';
import { getConfig } from '../../config/config-helper';
import ImportColumnLayout from '../layout/ImportColumnLayout';

export default function Import() {
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const { dataopsCatalogApi2Endpoint } = getConfig();
  const { id } = useParams();
  if (id === undefined) {
    return null;
  }
  const [responseData, setResponseData] = useState({
    status: 'pending',
    import_repo_url: '...',
    percent_complete: 0,
    dataops_project_id: '',
    dataops_url: '',
    listing_object: {},
  });
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(async () => {
      const response: AxiosResponse<any, any> = await axios.get(`${dataopsCatalogApi2Endpoint}/import/status?id=${id}`);

      const data = await response.data;

      setResponseData(data);
      if (data.status === 'done' || data.status === 'error') {
        navigate(`/new/from-git/${id}`);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [id, navigate, token]);
  return (
    <ImportColumnLayout>
      <div className="w-full rounded-md bg-dataops-light-blue/10 p-4">
        <div className="flex items-center space-x-4">
          <div className="grow text-sm overflow-ellipsis line-clamp-1 space-x-2 text-dataops-dark">
            <span>Importing</span>
            <a
              href={responseData.import_repo_url}
              target="_blank"
              rel="noreferrer"
              className="hover:underline text-sky-600"
            >
              {responseData.import_repo_url}
            </a>
          </div>
          <div className="overflow-hidden w-48 h-2 rounded-full bg-dataops-dark/10">
            <div
              style={{ width: `${responseData.percent_complete}%` }}
              className="h-2 rounded-full bg-dataops-dark-blue transition-all duration-300"
            />
          </div>
        </div>
      </div>
    </ImportColumnLayout>
  );
}
